
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";
export default defineComponent({
  mixins: [apiEndpoint],
  name: "kt-drawer-assessor-edit",
  components: { Field },
  props: {
    data: { type: Object },
  },
  setup() {},
  data() {
    return {
      formData: {
        employee_info: {
          father_name: "",
          mother_name: "",
          name: "",
          dob: "",
          nid: "",
          bcn: "",
          passport: "",
          driving_license: "",
          mobile: "",
          email: "",
          gender: "",
          religion: "",
          present_address: "",
          present_district: "",
          permanent_address: "",
          permanent_district: "",
        },
        attach_file_info: {
          attachment_type: "",
          created_at: "",
          created_by: "",
          file_name_original: "",
          file_name_uploaded: "",
          file_path: "",
          modified_by: null,
          table_name: "",
          table_row_id: "",
          thumb_dir: "",
          updated_at: "",
          verification_date: "",
          verification_status: "",
          x_attachment_type_id: "",
        },
        is_nsda_certified: "",
        is_foreign_trainer: "",
        is_bteb_registered: "",
        highest_level_of_education: "",
        bteb_registration_number: "",
        experience_of_years: "",
        is_guest_trainer: "",
        area_of_expertise: "",
        list_of_certification: "",
        tranche_id: "",
        entity_info_id: "",
        institute_info_id: "",
        course_id: "",
      },
      btbCheck: false,
      nsdCheck: false,
      nsdCheckStatus: "no",
      btbCheckStatus: "no",
      seipCheckStatus: "no",
      api_url: "",
      drawer: {},
      file_name: "",
      url: "",
      load: false,
      dialogImageUrl: "",
      imageUrl: "",
      dialogVisible: false,
      entityInfos: [],
      loading: false,
      entityTypes: [],
      avatarFiles: [],
      fileList: [],
      educational_details: [] as any,
      professional_details: [] as any,
      certificates: [] as any,
      tranche: [],
      institutes: [],
      empDistricts: [],
      courseList: [],
      certificate_data: [] as any,
    };
  },
  async created() {
    await this.getDistrict();
    this.api_url = this.VUE_APP_API_URL;
    this.emitter.on("edit-assessor-modal-data", async (data) => {
      // console.log("info of assesor" + JSON.stringify(data));
      this.educational_details = [];
      this.professional_details = [];
      if (data.is_bteb_registered == 1) {
        this.btbCheckStatus = "yes";
        this.btbCheck = true;
      }
      if (data.is_nsda_certified == 1) {
        this.nsdCheckStatus = "yes";
        this.nsdCheck = true;
      }
      if (data.seip_master_trainer == 1) {
        this.seipCheckStatus = "yes";
      }
      this.formData = data;
      let eduinfo = {
        id:"",
        institute_name: "",
        level_of_education: "",
        subject: "",
        passing_year: "",
      };
      let edu_data = data.educational_details;
      for (let i = 0; i < edu_data.length; i++) {
        eduinfo.id = edu_data[i].id;
        eduinfo.institute_name = edu_data[i].institute_name;
        eduinfo.level_of_education = edu_data[i].level_of_education;
        eduinfo.subject = edu_data[i].subject;
        eduinfo.passing_year = edu_data[i].passing_year;
        console.log(eduinfo);
        this.educational_details.push(JSON.parse(JSON.stringify(eduinfo)));
      }

      let professional_data = {
        id: "",
        designation: "",
        organization: "",
        address: "",
        exp_from: "",
        exp_to: "",
      };
      let profession_data_info = data.professional_details;
      for (let i = 0; i < profession_data_info.length; i++) {
        professional_data.id = profession_data_info[i].id;
        professional_data.designation = profession_data_info[i].designation;
        professional_data.organization = profession_data_info[i].organization;
        professional_data.address = profession_data_info[i].address;
        professional_data.exp_from = profession_data_info[i].exp_from;
        professional_data.exp_to = profession_data_info[i].exp_to;
        this.professional_details.push(
          JSON.parse(JSON.stringify(professional_data))
        );
      }
      // certificates
      let certificates_data = {
        id:"",
        certificate_name: "",
        organization_name: "",
        year: "",
      };
      let certificate_data_info= data.certificate;
      for (let i = 0; i < certificate_data_info.length; i++) {
        certificates_data.id=certificate_data_info[i].id;
        certificates_data.certificate_name =
          certificate_data_info[i].certificate_name;
        certificates_data.organization_name =
          certificate_data_info[i].organization_name;
        certificates_data.year = certificate_data_info[i].year;
        this.certificates.push(JSON.parse(JSON.stringify(certificates_data)));
      }
    });
  },
  methods: {
    bteb_registered(e) {
      if (e.target.checked) {
        this.formData.is_bteb_registered = "1";
        this.btbCheck = true;
        console.log("checked");
      } else {
        console.log("unchecked");
        this.btbCheck = false;
        this.formData.is_bteb_registered = "0";
      }
    },
    async getDistrict() {
      await ApiService.get("configurations/geo_district/list")
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    nsda_certified(e) {
      if (e.target.checked) {
        this.formData.is_nsda_certified = "1";
        this.nsdCheck = true;
      } else {
        this.formData.is_nsda_certified = "0";
        this.nsdCheck = false;
      }
    },
   

    addcertificate() {
      let certificates_data = {
        certificate_name: "",
        organization_name: "",
        year: "",
      };
      this.certificates.push(JSON.parse(JSON.stringify(certificates_data)));
    },
    addEducation() {
      let education = {
        institute_name: "",
        level_of_education: "",
        subject: "",
        passing_year: "",
      };
      this.educational_details.push(JSON.parse(JSON.stringify(education)));
    },
    addProfession() {
      let profession = {
        designation: "",
        organization: "",
        address: "",
        exp_from: "",
        exp_to: "",
      };
      this.professional_details.push(JSON.parse(JSON.stringify(profession)));
    },
    removecertificate() {
      this.certificates.length--;
    },
    removeProfession() {
      this.professional_details.length--;
    },
    removeEducation() {
      this.educational_details.length--;
    },
    async formSubmit() {
      this.loading=true;
      let data = new FormData();
      let user_id = VueCookieNext.getCookie("_seip_user");
      data.set('file_name',this.file_name);
      data.set("user_id", user_id.id);
      // for (let i = 0; i < this.certificates.length; i++) {
      //   let certificateData = {
      //     certificate_name: this.certificates.certificate_name[i],
      //     organization_name: this.certificates.organization_name[i],
      //     year: this.certificates.year[i],
      //   };
      //   this.certificate_data.push(certificateData);
      // } 
      data.append("certificates", JSON.stringify(this.certificates));
      data.append("professional", JSON.stringify(this.professional_details));
      data.append("educational", JSON.stringify(this.educational_details));

      for (var key in this.formData.employee_info) {
        data.set(key, this.formData.employee_info[key]);
      }
      for (var key in this.formData) {
        data.set(key, this.formData[key]);
      }
      // data.set("certificate_data", JSON.stringify(this.certificate_data));
      let route = this.VUE_APP_ASSESSOR_UPDATE_API;
      await ApiService.post(route, data)
        .then((response) => {
          this.loading=false;
          this.emitter.emit("assesor-updated", true);
          console.log(response);
          this.loading = false;
          if (response.status == 200 && response?.data?.status == "success") {
            Swal.fire({
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {});
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading=false;

          console.log(response);
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html:
              response?.data?.error ||
              "Unknown error occured. Please check all the required field",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },

    async getEntityInfos() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      await ApiService.get("entity/list?entity_id=" + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    updatePhoto(e) {
      this.file_name = e.target.files[0];
      this.url = URL.createObjectURL(this.file_name);
    },
    async getTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    handlePicturePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
});
